<template>
  <div class="paySuccess">
    <!-- 头部 -->
    <van-nav-bar fixed placeholder z-index="100" title="支付成功"   left-arrow @click-left="onClickLeft" />

    <!-- 内容 -->
    <div class="content">
        <div class="cbox">
          <div class="image">
             <img src="../assets/image/paySuccess.png" alt="">
             <p>支付成功</p>
          </div>
          <div class="text">
            <p>订单编号：<span class="text_content">{{paydata.oid}}</span></p>
            <p>实付金额：<span class="text_content redcolor">{{paydata.price}}元</span></p>
            <p>支付方式：<span class="text_content">{{paydata.payType}}</span></p>
          </div>
          <div class="btn" @click="onClickLeft">确定</div>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
         paydata:""
      }
    },
    methods:{
        onClickLeft:function(){
           this.$router.push('/home/InformationBase')
        }
    },
    created() {
       this.paydata = this.$route.query.paydata
    },
    mounted(){
        window.onClickLeft = this.onClickLeft
    },
  }
</script>

<style lang="scss" scoped>
.paySuccess{
   font-family: PingFang SC;
    ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
  .content{
    margin-top: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    .cbox{
       .image{
         display: flex;
         justify-content: center;
         flex-direction: column;
         align-items: center;
          img{
            width: 120px;
            height: 120px;
            margin-left: 20px;
          }
          p{
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            margin-top: 20px;
          }
       }
       .text{
         font-size: 14px;
         font-family: PingFang SC;
         font-weight: 500;
         color: #686868;
         margin-top: 25px;
         width: 240px;
         p{
           span{
             margin-left: 10px;
           }
           .redcolor{
             color: #EB451F;
           }
         }
       }
       .btn{
          margin-top: 40px;
          border: 1px solid transparent;
          background: #4588ff;
          width: 258px;
          height: 57px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FEFEFE;
          text-align: center;
          line-height: 57px;
          border-radius: 27px;
       }
    }
  }  
}
</style>